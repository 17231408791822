.loading-placeholder {
  overflow: hidden;
  background: #000;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #f6f7f8;
  background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
  background-size: 1300px;
}

.loading-placeholder.pie-chart {
  height: 300px;
  width: 300px !important;
  border-radius: 100%;
  margin: 0px auto;
}

.progress-bar {
  overflow: visible !important;
  position: relative;
  border-right: 1px solid rgba(248, 247, 247, 0.8);
}

.progress-bar:hover {
  opacity: 0.7;
  cursor: pointer;
}

.progress {
  /* overflow-x: hidden !important; */
  /* overflow-y: visible !important; */
  overflow: hidden !important;
  position: relative;
}

.progress-bar-indicator {
  position: relative;
  /* top: -20px; */
}

.progress-bar-indicator-bottom {
  top: auto;
  bottom: -20px;
  right: -30px;
}

.progress-bar-indicator-last {
  right: 10px;
  top: 7px;
  text-align: right;
}

.imp-scale .line {
  background-color: #333;
  position: relative;
  height: 2px;
  z-index: 1;
}

.imp-scale .line .marker {
  height: 10px;
  background-color: #333;
  width: 2px;
  overflow: hidden;
  margin-top: -4px;
  border-radius: 1px;
}

.imp-scale .arrow {
  position: relative;
  height: 2px;
  overflow: visible;
  background-color: #333;
  width: 40px;
}

.imp-scale .arrow .svg-icon {
  top: -12px;
}

.imp-scale .arrow.arrow-start .svg-icon {
  left: -9px;
}

.imp-scale .arrow.arrow-end .svg-icon {
  right: -9px;
}

.imp-scale .line .pointer {
  border-radius: 100%;
  height: 20px;
  width: 20px;
  background: #eb5c37;
  top: -10px;
  left: -10px;
  margin-left: 0%;
}

.risky {
  color: rgb(207, 154, 8) !important;
}

.very.risky {
  color: red !important;
}

.minimal.risk {
  color: rgb(107, 131, 2) !important;
}

.not.risky {
  color: rgb(7, 172, 7) !important;
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}

.action-separator {
  padding: 1px;
  display: inline-block;
  background: #0095e8;
  margin: 0px 10px 0px;
  position: relative;
  top: -2px;
  border-radius: 100%;
}

div.save-to-image {
  display: none;
  z-index: 20;
}

div:hover > div.save-to-image {
  display: block;
}
